import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";

function Header() {
  return (
    <header className="bg-beige text-gray-900">
      <div className="flex items-center justify-center px-8 py-3 mx-auto ">
        <nav className="block items-center w-auto divide-x divide-yellow-400">
          {[
            {
              route: `/#restauranger`,
              title: `Restauranger`,
            },
            {
              route: `/#meny`,
              title: `Meny`,
            },

            {
              route: `/#kontakt`,
              title: `Kontakt`,
            },
          ].map((link) => (
            <AnchorLink
              className="no-underline inline-block mt-0 px-2 sm:px-3 marker text-md uppercase"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </AnchorLink>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
