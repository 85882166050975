import PropTypes from "prop-types";
import React from "react";
import Footer from "./common/Footer";

import Header from "./header";
import Hero from "./index/Hero";

function Layout({ children }) {
  return (
    <div
      id="wrapper"
      className="flex flex-col antialiased text-gray-900 p-3 md:p-6 bg-gradient-to-b from-yellow-300 via-pink-500 to-teal-400"
    >
      <div className="bg-beige">
        <div className="flex h-screen/2 md:h-screen-90 flex-col">
          <div>
            <Header />
          </div>
          <div className="flex-grow relative flex w-full px-4 md:px-12">
            <Hero />
          </div>
        </div>
        <main className="flex-1 w-full ">{children}</main>
      </div>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
