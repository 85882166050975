import React from "react";
import LoggaStaende from "../common/LoggaStaende";
import Slider from "./Slider";

const Hero = () => {
  return (
    <div className="w-full relative">
      <Slider />
      <div className="absolute top-0 w-full h-full">
        <div className="h-full w-full  flex flex-col justify-center items-center md:justify-start md:items-start z-100">
          <div className="w-24 md:w-32 p-3 m-4 md:p-3 md:m-4">
            <LoggaStaende />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
