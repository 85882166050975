import React from "react";

function Footer() {
  return (
    <footer
      id="kontakt"
      className="bg-transparent text-white pt-8 pb-2 text-center md:text-left"
    >
      <nav className="flex flex-col md:flex-row justify-between items-center mx-auto text-md space-y-4 md:space-y-0">
        <p className="">Malmö Saluhall, Gilbraltargatan 6</p>
        <p className="text-center">
          parismalmosaluhall@gmail.com
          <br />
          0760-290025
        </p>
        <p>
          Besök oss på:{" "}
          <a
            className="underline"
            href="http://instagram.com/parismalmosaluhall/"
          >
            Instagram
          </a>{" "}
          /{" "}
          <a
            className="underline"
            href="https://www.facebook.com/parismalmosaluhall/"
          >
            Facebook
          </a>
        </p>
      </nav>
    </footer>
  );
}

export default Footer;
