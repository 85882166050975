import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import BackgroundSlider from "gatsby-image-background-slider";

function Slider() {
  return (
    <div className="h-full w-full relative">
      <BackgroundSlider
        imgStyle={{
          backgroundPosition: `bottom center`,
        }}
        style={{
          zIndex: "",
        }}
        duration={8}
        images={["hero3.jpg", "hero1.jpg", "hero4.jpg"]}
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { sourceInstanceName: { eq: "backgrounds" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        `)} // how long an image is shown
        // specify images to include (and their order) according to `relativePath`
      ></BackgroundSlider>
    </div>
  );
}

export default Slider;
